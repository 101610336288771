import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/stats-illustration.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import Portfolio from "components/features/portfolio.js";
import About from "components/features/VerticalWithAlternateImageAndText"
import OnlineStore from "components/cards/TabCardGrid_store.js";

import { SectionHeading } from "components/misc/Headings";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <About/>
      <Features
        subheading={<Subheading>Services</Subheading>}
        heading={
          <>
            How can I help <HighlightedText>you?</HighlightedText>
          </>
        }
      />

      <Portfolio
        subheading={<Subheading>Portfolio</Subheading>}
        heading={
          <>
            Nice service, nice <HighlightedText>Portfolio.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Portfolio - Testing & Optimization</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>Portfolio - Websites & Marketing</Subheading>}
        heading={
          <>
            Business development <HighlightedText>with Kamwest.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <OnlineStore
      subheading={<Subheading>Portfolio - Online Store Demo</Subheading>}
      heading={
        <>
          Online store for <HighlightedText>Photos</HighlightedText>
        </>
      }
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}

import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import ShoppingCart from "images/sale.svg"
import addTo from "images/bag.svg"
import check from "images/check.svg"

import Metro from "images/Finland/IMG_5854.JPG"
import Aalto from "images/Finland/IMG_5938.JPG"
import Dock from "images/Finland/IMG_6292.JPG"
import J from "images/Finland/IMG_6347.JPG"
import Hanko from "images/Finland/IMG_6648.JPG"
import Ruka from "images/Finland/IMG_7294.JPG"
import Kallio from "images/Finland/IMG_7732.JPG"
import Loviisa from "images/Finland/IMG_7795.JPG"

import Bromo from "images/Asia/IMG_3380.JPG"
import Bromo1 from "images/Asia/IMG_3460.JPG"
import Bromo2 from "images/Asia/IMG_3527.JPG"
import Ijen from "images/Asia/IMG_3888.JPG"
import Ele from "images/Asia/IMG_4825.JPG"
import Tea from "images/Asia/IMG_5176.JPG"
import Taroko from "images/Asia/IMG_5437.JPG"
import Sg from "images/Asia/IMG_0932.JPG"

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;

const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Subheading = tw(SubheadingBase)`flex justify-between items-center flex-col xl:flex-row text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const HighlightedText = tw.span`text-primary-500`;

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const HeaderRow2 = tw.div`flex justify-between xl:flex-row`;
const CartRow = tw.div`flex justify-between items-center xl:flex-row`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-5 xl:mt-12`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-8 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-0 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-6 mb-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;
const AddButton = styled.button`
${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
.imageContainer {
  ${tw`text-center bg-gray-300 rounded-full p-3 flex-shrink-0`}
  img {
    ${tw`w-6 h-6`}
  }
}
`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const CartPrice = tw.p`m-2 text-xl font-bold`;
const CartTitle = tw.h5`m-2 text-lg font-semibold group-hover:text-primary-500`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

/* const Image = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center mx-4 px-2 py-8 xl:py-0`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }
`; */

const CartButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }
`;

const CartImages = styled.div`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .imageContainer {
    ${tw`border text-center m-4 rounded-full p-0 flex-shrink-0`}
    img {
      ${tw`w-24 h-24 rounded-full`}
    }
  }
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const StyledImgModal = styled(ReactModalAdapter)`
  &.imgHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.imgHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const CloseImgModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  heading = "Online store for photography",
  subheading = "Portfolio - Online store demo",
  cartText = "Your shopping cart",
  tabs = {
    Asia: [
      {
        imageSrc:Bromo,
        title: "Sunrise of a Lifetime",
        content: "Mt. Bromo, Indonesia",
        price: "5.99",
        rating: "5.0",
        id: 1,
        added: false
      },
      {
        imageSrc:Bromo1,
        title: "On the edge - 1",
        content: "Mt. Bromo, Indonesia",
        price: "8.99",
        rating: "4.8",
        id: 2,
        added: false
      },
      {
        imageSrc:Bromo2,
        title: "On the edge - 2",
        content: "Mt. Bromo, Indonesia",
        price: "10.99",
        rating: "4.9",
        id: 3,
        added: false
      },
      {
        imageSrc: Ijen,
        title: "Crater",
        content: "Crater Ijen, Indonesia",
        price: "8.99",
        rating: "4.6",
        id: 4,
        added: false
      },
      {
        imageSrc: Ele,
        title: "Taipei at Night",
        content: "Taipei, Taiwan",
        price: "9.99",
        rating: "4.2",
        id: 5,
        added: false
      },
      {
        imageSrc: Tea,
        title: "At the Top",
        content: "Tea Top Mountain, Taiwan",
        price: "9.99",
        rating: "5.0",
        id: 6,
        added: false
      },
      {
        imageSrc: Sg,
        title: "Singapore with a view",
        content: "Singapore",
        price: "3.99",
        rating: "4.2",
        id: 7,
        added: false
      },
      {
        imageSrc:Taroko,
        title: "Taroko Gorge",
        content: "Hualien, Taiwan",
        price: "3.99",
        rating: "3.9",
        id: 8,
        added: false
      }
    ],
    Finland: [
      {
        imageSrc:
          Metro,
        title: "Deserted Subway",
        content: "Subway, Helsinki",
        price: "5.99",
        rating: "5.0",
        id: 9,
        added: false
      },
      {
        imageSrc:
          Aalto,
        title: "Focus",
        content: "Abloc, Aalto University",
        price: "2.99",
        rating: "4.8",
        id: 10,
        added: false
      },
      {
        imageSrc:Dock,
        title: "Port",
        content: "Jätkäsaari, Helsinki",
        price: "7.99",
        rating: "4.9",
        id: 11,
        added: false
      },
      {
        imageSrc: Hanko,
        title: "Summer Days",
        content: "Hanko",
        price: "8.99",
        rating: "4.6",
        id: 12,
        added: false
      },
      {
        imageSrc: Ruka,
        title: "Winter Wonderland",
        content: "Ruka, Kuusamo",
        price: "7.99",
        rating: "4.2",
        id: 13,
        added: false
      },
      {
        imageSrc: J,
        title: "Foggy Construction",
        content: "Jätkäsaari, Helsinki",
        price: "2.99",
        rating: "5.0",
        id: 14,
        added: false
      },
      {
        imageSrc: Kallio,
        title: "Pastel City",
        content: "Kallio, Helsinki",
        price: "3.99",
        rating: "4.2",
        id: 15,
        added: false
      },
      {
        imageSrc:Loviisa,
        title: "Old Fashioned",
        content: "Loviisa",
        price: "3.99",
        rating: "3.9",
        id: 16,
        added: false
      }
    ],
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  const [cards, SetCards] = useState()

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [imgText, setImgText] = useState("");
  const [imgLoc, setImgLoc] = useState("");

  const toggleImgModal = (imageSrc, imageText, imageLoc) => {
    setImgText(imageText)
    setImgSrc(imageSrc)
    setImgLoc(imageLoc)
    setImgModalIsOpen(!imgModalIsOpen)  
  };

  const [CartItems, AddToCart] = useState([]);
  const[Total, SetTotal] = useState(0)
  const Adding = (card) => {
    if (CartItems.find(item => item.id === card.id)){
      AddToCart(CartItems.filter(n => n.id !== card.id))
    }else{
      AddToCart(CartItems.concat(card))
    }
  };

  const InCart = ({card}) => {
    if (CartItems.find(item => item.id === card.id)){
      return (
        <span className="imageContainer">
          <img src={check} alt="" />
        </span>
    )}
    return (
      <span className="imageContainer">
          <img src={addTo} alt="" />
        </span>
    )
};

  return (
    <Container>
      <ContentWithPaddingXl>
      <Subheading>{subheading}</Subheading>
        <HeaderRow>
          <Heading>{heading}</Heading>
          <CartButton onClick={toggleModal}>
            <span className="imageContainer">
              <img src={ShoppingCart} alt="" />
            </span>
          </CartButton>
        </HeaderRow>
        <HeaderRow>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton onClick={() => toggleImgModal(card.imageSrc, card.title, card.content)}>View</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <HeaderRow2>
                      <CardPrice>{card.price}€</CardPrice>
        
                      
                      <AddButton onClick={() => Adding(card)}>
                        <InCart card = {card}/>
                      </AddButton>


                    </HeaderRow2>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
      <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <span className="content">
            <TwoColumn>
              <LeftColumn>
                  <Heading>{  
                  <>
                    Your shopping <HighlightedText>Cart</HighlightedText>
                  </>
                }
                </Heading>
              </LeftColumn>
              <RightColumn>
              <ul>
                {CartItems.map((card, index) => 
                <li key={index}>
                  <CartRow>
                    <CartImages>
                      <span className="imageContainer">
                        <img src={card.imageSrc} alt="" />
                      </span>
                    </CartImages>
                    <CartTitle>{card.title}</CartTitle>
                    <CartPrice>{card.price}</CartPrice>
                  </CartRow>
                  </li>)}
              </ul>
              </RightColumn>
            </TwoColumn>
          </span>
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
        </StyledModal>

      <StyledImgModal
        closeTimeoutMS={300}
        className="imgHeroModal"
        isOpen={imgModalIsOpen}
        imgSrc = {imgSrc}
        imgText = {imgText}
        imgLoc = {imgLoc}
        onRequestClose={toggleImgModal}
        shouldCloseOnOverlayClick={true}
      >
        <span className="content">
          <TwoColumn>
            <LeftColumn>
              <Heading>{imgText}</Heading>
              <Subheading>{imgLoc}</Subheading>
            </LeftColumn>
            <RightColumn>
            <img src={imgSrc}></img>
            </RightColumn>
          </TwoColumn>
        </span>
        <CloseImgModalButton onClick={toggleImgModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseImgModalButton>
      </StyledImgModal> 
    </Container>
  );
};